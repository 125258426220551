import React, { CSSProperties, ReactElement } from "react";

import styles from "./style.module.scss";

export default function NewJerseyDisclaimer({
    backGround,
    textColor,
}: {
    backGround?: string;
    textColor?: string;
}): ReactElement {
    const outerSectionStyle = {
        "--bg": `${backGround ?? "gray"}`,
    } as CSSProperties;
    return (
        <section className={styles["mainContainer"]} style={outerSectionStyle}>
            <p
                style={{ color: textColor ?? " black" }}
                className="lg:text-sm text-xs lg:leading-6 font-light text-center py-2 max-w-[900px] mx-auto"
            >
                New Jersey residents: The product being offered is a service
                contract and is separate and distinct from any product or
                service warranty which may be provided by the home builder or
                manufacturer.
            </p>
        </section>
    );
}
