import React, { ReactElement } from "react";
import Image from "next/image";

export default function WhatIsHomeWarranty(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row lg:gap-32 gap-16   lg:mt-24 mt-12">
            <div className=" max-w-[575px] mx-auto lg:mx-0 mt-12">
                <h2 className="font-bold lg:text-3xl text-xl lg:leading-[45px] text-[#1D2458] ">
                    What Is a Home Warranty?
                </h2>
                <p className="mt-5   lg:text-lg  text-base lg:leading-8 font-light">
                    A home warranty is a plan that covers the cost of repairs or
                    replacements for your household appliances and systems.
                    Similar to a yearly subscription, it requires annual
                    renewal. However, the extent of coverage and terms may vary
                    based on the selected plan.
                </p>
                <span className="block w-[173px] h-[6px] bg-[#24317C] mt-6"></span>
            </div>
            <div className="w-[250px] h-[180px] md:w-[433px] md:min-w-[433px] md:h-[330px]  relative  mx-auto lg:mx-0">
                <Image
                    src={
                        "/homewarranty/assets/images/whatIsHomeWarrantyImg.png"
                    }
                    alt="Image"
                    layout="fill"
                />
            </div>
        </section>
    );
}
