import React, { ReactElement } from "react";

import Image from "next/image";

export default function HowIsDifferent(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row lg:gap-28 gap-16     lg:pl-20  lg:mt-32 mt-16 ">
            <div className="max-w-[575px] mx-auto lg:mx-0 order-1 lg:order-2 ">
                <h2 className="font-bold lg:text-3xl text-xl lg:leading-[45px] text-[#1D2458] ">
                    How Is a Home Warranty Different From Homeowner's Insurance?
                </h2>
                <p className="mt-5 font-light   lg:text-lg  text-base lg:leading-7">
                    A home warranty differs from homeowner's insurance, which
                    only covers damages to your home's structure and property.
                    Instead, a home warranty focuses on your appliances and
                    systems, providing additional protection. While a home
                    warranty doesn't replace homeowner's insurance, it can still
                    be a smart investment for homeowners. If you have recently
                    purchased a home, some of your home systems may already be
                    covered by manufacturer warranties.
                </p>
                <span className="block w-[173px] h-[6px] bg-[#24317C] mt-6"></span>
            </div>
            <div className="w-[270px] h-[230px] md:w-[450px] md:min-w-[450px] md:h-[433px]  relative  mx-auto lg:mx-0 order-2 lg:order-1 ">
                <Image
                    src={"/homewarranty/assets/images/howIsDifferentImg.png"}
                    alt="Image"
                    layout="fill"
                />
            </div>
        </section>
    );
}
